body {
    margin: 0;
    padding: 0;
    background-color: #fefefe;
}

.container {
    max-width: 960px;
    margin: 0 auto;
}

.box {
    background-color: #fefefe;
    box-shadow: 1px 1px 5px 0 rgba(100, 100, 100, 0.2);
    padding: 20px;
    border-radius: 2px;
}

header {
    background-image: url(/assets/images/photos/portret.jpg);
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: calc(50% - 40px) 170px;
    box-sizing: border-box;
    height: 400px;

    h1 {
        font-size: 3rem;

        br {
            display: none;
        }
    }

    .container {
        text-align: center;
    }

    @media (max-width: 370px) {
        background: none;
        height: auto;
    }

    @media (min-width: $mobile-break) {
        background-size: contain;
        background-position: calc(50% - 300px) calc(100%);
        height: 320px;

        h1 {
            font-size: 6.5rem;

            br {
                display: inline;
            }
        }

        &.main-hero {
            height: 55vh;
            min-height: 320px;
        }

        .container {
            text-align: right;
        }
    }

    nav {
        max-width: 100%;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin: 0 10px;
            }
        }
    }
}

header,
footer {
    a {
        color: #3A4145;

        &.active {
            border-bottom: 2px solid;
        }
    }
}

header a.active {
    border-bottom: 2px solid;
}

header,
footer,
section {
    padding: 30px 20px;

    @media (min-width: $mobile-break) {
        padding: 30px 40px;
    }
}

.content img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    border-radius: 2px;
}

.main {
    @extend .light-section;
    font-size: 2.25rem;
}

.light-section {
    background-color: #f5f5f7;
    box-shadow: inset 0px 15px 15px -15px rgba(100, 100, 100, 0.2);
}

.dark-section {
    background-color: #383f6e;
    color: #c1c1c1;
    box-shadow: inset 0px 15px 15px -15px rgba(0, 0, 0, 0.5);

    a {
        color: #efefef;
        transition: color 0.2s ease;

        &:hover {
            color: #fefefe;
        }
    }
}

.map-section {
    padding: 0;

    #map {
        height: 300px;
    }
}

.quote-section {
    @extend .dark-section;
    font-size: 3rem;
    font-style: italic;
    padding: 70px 20px;

    .container {
        max-width: 700px;
        text-align: center;
    }
}

.logos-section {
    padding: 30px 40px 30px;

    .container {
        display: flex;

        a {
            display: block;
            width: 30%;
            text-align: center;
        }
    }
}

footer {
    position: relative;
    font-size: 1.9rem;
    background-color: #f5f5f7;

    @media (min-width: $mobile-break) {
        padding: 20px 40px;
    }

    h2 {
        font-size: 2.5rem;
        margin: 0 0 30px 0;
    }

    .footer-columns {
        display: flex;
        flex-flow: wrap;
        margin: -10px -10px 10px -10px;
    }

    .legal-line {
        width: 100%;
        padding: 30px 0 0 0;
        margin: 0;

        a {
            font-style: italic;
        }
    }
}

.footer-links {
    flex: 1 0 180px;
    margin: 10px;
    padding: 0;

    li {
        list-style: none;
        margin: 15px 0;

        a {
            svg {
                fill: #3A4145;
                margin-right: 10px;
                transition: fill 0.2s ease;
                vertical-align: middle;
                position: relative;
                top: -2px;
                width: 22px;
                height: 22px;
            }

            &:hover svg {
                fill: #73a9c9;
            }
        }
    }
}

.columns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    .column {
        flex: 1 1 250px;
        margin: 20px;
    }
}

.contact-info {
    margin: 0 0 25px 0;
}


.post-list {
    list-style: none;
    padding: 0;
}

.pagination {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;

    a,
    span {
        flex: 1;
    }
}

.post-comments {
    @extend .box;
    margin-top: 20px;
}

.post-summary {
    @extend .box;
    display: flex;
    flex-direction: column;

    @media (min-width: $mobile-break) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    &:not(:last-child) {
        margin-bottom: 30px;
    }
}

.post-summary-excerpt {
    margin-bottom: 0;
}

.post-summary-content.has-post-summary-image {
    flex: 0 1 75%;
}

.post-summary-image {
    flex: 0 1 25%;
    box-sizing: border-box;
    margin-bottom: 15px;

    @media (min-width: $mobile-break) {
        margin-bottom: 0;
        padding-right: 20px;
    }
}

.post-summary-details {
    color: #75828a;
    font-size: 1.9rem;
}
