html {
	font-size: 9px;
	-webkit-tap-highlight-color: transparent;

	@media (min-width: $mobile-break) {
		font-size: 10px;
	}
}

body {
	line-height: 1.75;
	font-size: 2em;
}

body,
input,
textarea {
	font-family: 'Vollkorn', Georgia, serif;
	color: #3A4145;
	text-rendering: geometricPrecision;
}

label,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Noto Serif Display', Georgia, serif;
	font-weight: 400;
	text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	letter-spacing: 0.18em;
	line-height: 1.5;

	&:not(:first-child) {
		margin-top: 2.2em;
	}
}

h1 {
	font-size: 6.5rem;
	line-height: 80px;
	margin: 0 -10px 0 0;

	@media (min-width: $mobile-break) {
		line-height: 100px;
		font-size: 8rem;
	}
}

h2 {
	font-size: 3.6rem;
}

h3 {
	font-size: 2.7rem;
}

h4 {
	font-size: 2.3rem;
}

h5 {
	font-size: 2rem;
}

h6 {
	font-size: 2rem;
}

a {
	color: #4489b1;
	transition: color 0.2s ease;
	text-decoration: none;

	&:hover {
		color: #73a9c9;
	}
}
